import React from 'react';

import Image from '@/core/components/Image';

interface DonationCardPhotoProps {
  photo: string;
  title: string;
  height?: 196 | 128;
  width?: 'full' | 118;
  horizontal?: true;
}

const DonationCardPhoto: React.FC<DonationCardPhotoProps> = ({ photo, title, height, width, horizontal }) => {
  let photoSize: string = '';
  if (horizontal) {
    photoSize = 'h-[104px] w-25 lg:h-[116px] lg:w-[118px]';
  } else {
    const photoHeight = height === 128 ? 'h-[128px]' : 'h-49';
    const photoWidth = width === 118 ? 'w-[118px]' : 'w-full';
    photoSize = `${photoHeight} ${photoWidth}`;
  }

  return (
    <div className={`${photoSize} shrink-0 rounded-1`}>
      <div className="size-full overflow-hidden rounded-1">
        <Image
          src={photo.replace('jpg', 'webp')}
          type="image/webp"
          fallback={photo}
          fallbackType="image/jpeg"
          alt={title}
          className="size-full self-center rounded-1 object-cover transition-transform duration-300 hover:scale-110"
          loading="lazy"
          decoding="async"
          height={height}
          width={118}
        />
      </div>
    </div>
  );
};

export default DonationCardPhoto;
